var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"newpackage"},[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/operate/datapackage' }}},[_vm._v("人群管理")]),_c('el-breadcrumb-item',[_vm._v("新建人群包")])],1),_c('div',{staticClass:"newpackage__form"},[_c('el-form',{ref:"form",staticClass:"w-form",attrs:{"label-width":"146px","model":_vm.form,"rules":_vm.rules}},[_c('el-form-item',{attrs:{"prop":"name","label":"人群包名称"}},[_c('w-input-valid',{attrs:{"placeholder":"请输入人群包名称","minTextLength":1,"maxTextLength":50},on:{"input":function (v) { _vm.form.name = v }},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"prop":"source_type","label":"新建方式"}},[_c('el-radio-group',{model:{value:(_vm.form.source_type),callback:function ($$v) {_vm.$set(_vm.form, "source_type", $$v)},expression:"form.source_type"}},_vm._l((_vm.typeOptions),function(ref){
var id = ref.id;
var name = ref.name;
return _c('el-radio',{key:id,attrs:{"label":id},on:{"change":_vm.sourceTyppeChange}},[_vm._v(_vm._s(name))])}),1)],1),_c('el-form-item',{staticClass:"source-item",attrs:{"prop":"compose_type","label":"数据组合关系"}},[_c('w-tooltip',{staticClass:"label-tooltip",attrs:{"content":"人群包数据组合的关系，包括以下类型：<br>\n                  并集：合并多个活动单元人群<br>\n                  交集：取多个活动单元人群中重叠的部分人群；如果选择2个以上的人群包，取多个集合的重合设备码<br>\n                  排除：取「数据来源」选择的活动单元人群并集，再排除掉「排除数据」中选择的活动单元的并集。"}}),_c('el-radio-group',{model:{value:(_vm.form.compose_type),callback:function ($$v) {_vm.$set(_vm.form, "compose_type", $$v)},expression:"form.compose_type"}},_vm._l((_vm.sourceOptions),function(ref){
var id = ref.id;
var name = ref.name;
return _c('el-radio',{key:id,attrs:{"label":id},on:{"change":_vm.composeTypeChange}},[_vm._v(_vm._s(name))])}),1)],1),_c('el-form-item',{staticClass:"data-type-list",attrs:{"prop":"data_type_list","label":"数据类型"}},[_c('el-checkbox-group',{on:{"change":_vm.transformTypesChange},model:{value:(_vm.form.data_type_list),callback:function ($$v) {_vm.$set(_vm.form, "data_type_list", $$v)},expression:"form.data_type_list"}},_vm._l((_vm.transformTypesOptions),function(ref){
var id = ref.id;
var name = ref.name;
return _c('el-checkbox',{key:id,attrs:{"label":id}},[_vm._v(_vm._s(name))])}),1)],1),_c('el-form-item',{staticClass:"item-transform-list",attrs:{"prop":"active_list","label":"数据来源"}},[_c('transform-list',{attrs:{"source":_vm.activitySource},model:{value:(_vm.form.active_list),callback:function ($$v) {_vm.$set(_vm.form, "active_list", $$v)},expression:"form.active_list"}})],1),(_vm.form.compose_type === 3)?_c('el-form-item',{staticClass:"item-transform-list",attrs:{"prop":"remove_list","label":"排除数据"}},[_c('transform-list',{attrs:{"source":_vm.activitySource},model:{value:(_vm.form.remove_list),callback:function ($$v) {_vm.$set(_vm.form, "remove_list", $$v)},expression:"form.remove_list"}})],1):_vm._e()],1),_c('div',{staticClass:"newpackage-btns"},[_c('w-button',{ref:"submitButton",attrs:{"class-name":"bottom-btn confirm","type":"primary","round":"","subBtn":""},on:{"click":_vm.addPackage}},[_vm._v("确定")]),_c('w-button',{attrs:{"class-name":"bottom-btn cancel","round":""},on:{"click":_vm.cancel}},[_vm._v("取消")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }