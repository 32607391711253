/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-21 14:03:39
 * @LastEditors: Arvin
 * @LastEditTime: 2020-12-24 14:43:09
 */
import { numberFormat, wGetTextLength } from '@/plugins/utils'
export default {
  name: 'TransformList',
  props: {
    source: {
      type: Array,
      default: () => ([])
    },
    value: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    selectedTotalNumber () {
      return this.source.reduce((res, { childs }) => {
        return childs.reduce((res1, { id, number }) => {
          console.log(number)
          if (this.selected.includes(id)) {
            res1 -= 0
            number -= 0
            res1 += number
          }
          return res1
        }, res - 0)
      }, 0)
    }
  },
  data () {
    return {
      selected: []
    }
  },
  watch: {
    selected (newValue) {
      this.$emit('input', newValue)
    },
    value (newValue) {
      this.selected = newValue
    }
  },
  mounted () {
    this.selected = this.value
  },
  methods: {
    numberFormat,
    wGetTextLength
  }
}
